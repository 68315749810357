.marker-cluster span {
    font-weight: 600;
}

.marker-icon-custom {
    filter: drop-shadow(0px 0px 8px rgba(45, 46, 202, 0.6));
}

.leaflet-popup-content {
    margin: 14px !important;
}

.marker-popup-container {
    font-family: 'SF Compact Text', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

    display: flex;
    flex-direction: column;

    p {
        font-size: .875rem;
        font-weight: 400;

        &:first-child {
            margin-top: 0 !important;
        }

        span {
            text-transform: capitalize;
        }
    }

    .btn {
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
    }

    .marker-img {
        max-height: 150px;
        object-fit: cover;
    }
}