/*
Theme Name: ReactJS With Redux Templat
Author: Bao Tuan Tran
Author URI: https://baholdings.vn
Description: ReactJS With Redux Template
Version: 0.1.0
Tags:

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Alignments
# Layouts
# Header
# Sidebar
# Footer
# Content
	## Pages
	## Posts and Comments
# Media
# Responsive
# Animations
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

@import 'variables';
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-pro/css/all.min.css';

@font-face {
	font-family: 'SF Compact Text';
	font-weight: 700;
	font-style: normal;
	src: url('../fonts/SFCompactText/SF-Compact-Text-Bold.otf') format('opentype');
}
@font-face {
	font-family: 'SF Compact Text';
	font-weight: 700;
	font-style: italic;
	src: url('../fonts/SFCompactText/SF-Compact-Text-BoldItalic.otf') format('opentype');
}
@font-face {
	font-family: 'SF Compact Text';
	font-weight: 600;
	font-style: normal;
	src: url('../fonts/SFCompactText/SF-Compact-Text-Semibold.otf') format('opentype');
}
@font-face {
	font-family: 'SF Compact Text';
	font-weight: 600;
	font-style: italic;
	src: url('../fonts/SFCompactText/SF-Compact-Text-SemiboldItalic.otf') format('opentype');
}
@font-face {
	font-family: 'SF Compact Text';
	font-weight: 500;
	font-style: normal;
	src: url('../fonts/SFCompactText/SF-Compact-Text-Medium.otf') format('opentype');
}
@font-face {
	font-family: 'SF Compact Text';
	font-weight: 500;
	font-style: italic;
	src: url('../fonts/SFCompactText/SF-Compact-Text-MediumItalic.otf') format('opentype');
}
@font-face {
	font-family: 'SF Compact Text';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/SFCompactText/SF-Compact-Text-Regular.otf') format('opentype');
}
@font-face {
	font-family: 'SF Compact Text';
	font-weight: 400;
	font-style: italic;
	src: url('../fonts/SFCompactText/SF-Compact-Text-RegularItalic.otf') format('opentype');
}

body {
	font-family: 'SF Compact Text', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	word-wrap: normal;
	font-weight: 600;
	max-width: 100%;
}
p {
	word-wrap: break-word;
	max-width: 100%;
	
	a {
		font-weight: 600;
		color: inherit;

		&:hover, &:focus {
			color: var(--primary);
		}
	}
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
a {
	text-decoration: none;
}
a, button {
	cursor: pointer;
	outline: none !important;
}
.btn {
	font-weight: 500;
}
a, button, .btn, .dropdown-item, .nav-link {
	i {
		margin-right: 8px;
	}
}
.btn-icon i {
	margin-right: 0 !important;
}
.btn-icon-right i {
	margin-right: 0;
	margin-left: 8px;
}
.btn-rounded {
	border-radius: 1.5rem;
}
.btn-link {
	padding: 0;
	text-decoration: none !important;

	&:focus {
		box-shadow: none;
	}
}

ul, ol {
	padding-inline-start: 17px;
}

.dropdown-menu {
	.dropdown-item {
		&:focus {
			background-color: var(--bs-primary);
		}
	}
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
.form-label {
	font-weight: 600;
}
.form-control {
	border: none !important;
	background-color: var(--bs-gray-200) !important;

	&::placeholder {
		color: var(--bs-gray-500);
	}
}
.input-group {
	.form-control {
		&:focus, &.active {
			box-shadow: none !important;
		}
	}
	.input-group-text {
		border: none !important;
		padding-left: 12px;
		padding-right: 12px;
		color: var(--bs-blue) !important;
		background-color: var(--bs-white) !important;
		transition: box-shadow 0.15s ease-in-out;
		
		&.form-solid {
			color: var(--bs-white) !important;
			background-color: rgba($color: var(--bs-blue), $alpha: 0.5) !important;
			border-color: rgba($color: var(--bs-blue), $alpha: 0.5) !important;
		}
	}
}

/*--------------------------------------------------------------
# Navigation
	## Links
--------------------------------------------------------------*/
button.nav-link {
	border: none;
    background: none;
    width: 100%;
    text-align: inherit;
}

/*--------------------------------------------------------------
# Navigation
	## Menus
--------------------------------------------------------------*/
.navbar-toggler {
	border-color: transparent !important;
	padding: .35rem .25rem;
	box-shadow: none !important;
}

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
.site {
	position: relative;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.sec-header {
	margin-bottom: 16px;

	.sec-title {
		font-size: 1.5rem;
		margin-bottom: .25rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.sec-description {
		color: var(--bs-gray-600);
		margin-bottom: 0;
	}
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.site-copyright {
	padding-top: 2rem;
	padding-bottom: 2rem;
	text-align: center;

	p {
		margin-bottom: 0;
		font-weight: 600;
	}
}


/*--------------------------------------------------------------
# Content
	## Pages
--------------------------------------------------------------*/
.page-section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

/*--------------------------------------------------------------
# Content
	## Posts and Comments
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes fadeIn {
	0%      { opacity: 0; }
	100%    { opacity: 1; }
}