@import 'assets/scss/variables.scss';

.cover-container {
    height: 51vh !important;
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);

    main {
        max-width: 42em;
    }
}

#map {
    position: relative;
}

.nft-maps {
    position: relative;
    height: 70vh;
}

.map-loading-indicator {
    display: none;
    position: absolute;
    z-index: 1020;
    bottom: 1.25rem;
    right: 1rem;
    padding: .25rem .5rem;
    color: #fff;
    background-color: var(--bs-primary);
    border-radius: .25rem;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .5);

    &.show {
        display: block;
    }
}

.map-style-selector {
    font-size: .875rem;
    position: absolute;
    z-index: 1020;
    bottom: 1rem;
    left: 1rem;

    .form-check {
        padding-left: 0;
        margin-bottom: 0;
    }

    .form-check-input:checked + .form-check-label {
        outline-color: var(--bs-primary);
    }

    .form-check-label {
        cursor: pointer;
        display: block;
        position: relative;
        border: 2px solid #fff;
        border-radius: .5rem;
        outline: 2px solid transparent;
        overflow: hidden;
        margin: 0;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, .5);
    }

    .map-style-img {
        width: 64px;
        height: 64px;

        @media (max-width: 768px) {
            width: 48px;
            height: 48px;
        }
    }

    .map-style-label {
        position: absolute;
        bottom: 6px;
        left: 6px;
        right: 6px;
        font-weight: 600;

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.map-search-box {
    position: absolute;
    top: 1rem;
    left: calc(50% - 25vw);
    z-index: 1020;
    width: 50vw;
    border-radius: .25rem;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);

    @media (max-width: 768px) {
        left: calc(50% - 50vw + 1rem);
        width: calc(100vw - 2rem);
    }

    .form-control {
        background-color: #fff !important;
    }

    .map-search-results {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: .25rem;
        border-radius: .25rem;
        max-height: calc(70vh - 8rem);
        overflow-x: hidden;
        overflow-y: scroll;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);

        &.show {
            display: block;
        }

        .map-search-result-item {
            cursor: pointer;
            padding: .5rem 1rem;
            text-transform: capitalize;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.select-country-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;

    .select-country-item {
        &:not(:last-child) {
            margin-bottom: .75rem;
        }
        .btn {
            display: flex;
            width: 230px;
            margin: auto;

            .select-country-flag {
                width: 30px;
                height: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 1rem;
            }
            .select-country-label {
                flex-grow: 1;
                text-align: center;
            }
        }
    }
}

.btn-select-country-on-maps {
    display: flex;
    position: absolute;
    z-index: 1020;
    bottom: 1.25rem;
    right: 1rem;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, .15);
    padding: .375rem .5rem;

    .select-country-flag {
        width: 30px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: .5rem;
    }
    .select-country-label {
        flex-grow: 1;
        text-align: center;
    }
}