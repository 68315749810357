@import 'assets/scss/variables.scss';

.page-auth {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.auth-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    overflow: hidden;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    color: var(--bs-primary);
    background-color: var(--bs-white);
    border-radius: 16px;
    margin: auto;
    margin-bottom: 6rem;
}

.auth-form {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 414px;
    border: none;
    border-radius: .5rem;
    background-color: var(--bs-white);

    &.register-form {
        max-width: unset;
    }

    .card-header {
        padding: 1.5rem;
    }

    .card-body {
        padding: 1.5rem;
    }

    .card-footer {
        padding: 1rem 1.5rem;
    }
}