.app-alert-wrapper {
    display: block;
    position: fixed;
    z-index: 1050;
    bottom: 0;
    right: 0;
    width: calc(288px + 2rem);
    padding: 1rem;
    max-height: calc(100vh - 2rem);
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: all 200ms ease;

    .toast {
        position: relative;
        opacity: 0;
        transition: opacity 200ms ease;
        overflow: hidden;
        border: none;

        &:not(:only-child) {
            margin-top: .5rem;
        }

        &.show-toast {
            opacity: 1;
        }

        .btn-close {
            box-shadow: none !important;
        }

        .toast-timer {
            position: relative;
            height: 4px;
            background-color: rgba(0,0,0,.25);

            .toast-timer-progressbar {
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 4px;
                animation: width100to0 5s ease;
                background-color: rgba(255, 255, 255, .5);
            }
        }

        &.toast-info {
            .toast-body {
                color: #fff !important;
                background-color: var(--bs-info);
            }

            .toast-timer {
                background-color: var(--bs-info);
            }
        }

        &.toast-success {
            .toast-body {
                color: #fff !important;
                background-color: var(--bs-success);
            }

            .toast-timer {
                background-color: var(--bs-success);
            }
        }

        &.toast-warning {
            .toast-body {
                color: #fff !important;
                background-color: var(--bs-warning);
            }

            .toast-timer {
                background-color: var(--bs-warning);
            }
        }

        &.toast-danger {
            .toast-body {
                color: #fff !important;
                background-color: var(--bs-danger);
            }

            .toast-timer {
                background-color: var(--bs-danger);
            }
        }
    }
}

@keyframes width100to0 {
    0%      { width: 100%; }
    100%    { width: 0%; }
}