// Bootstrap Override
$theme-colors: (
    "primary": #3449FD,
    "secondary": #4F4F4F,
    "success": #87C523,
    "info": #0dcaf0,
    "warning": #F55B29,
    "danger": #F20000,
    "light": #F6F6F6,
    "dark": #212529,
);

$font-size-base: .875rem;

// Colors
$gray1: #333333;
$gray2: #4F4F4F;
$gray3: #828282;
$gray4: #BDBDBD;
$gray5: #E0E0E0;
$gray6: #F2F2F2;