@import 'assets/scss/variables.scss';

.swiper-container {
    display: flex;
    flex-direction: column-reverse;
	width: 100%;
	height: 100%;
}

.swiper-slide {
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-pagination {
    position: relative;
    top: unset !important;
    bottom: unset !important;
    left: unset !important;
    right: unset !important;


    .swiper-pagination-bullet {
        width: 24px;
        height: 4px;
        border-radius: 2px;
        background-color: rgba($color: $gray2, $alpha: 0.5);
        transition: background-color 200ms ease;
    }
    .swiper-pagination-bullet-active {
        background-color: var(--bs-blue);
    }
}